import React from 'react'
import { useEffect } from 'react'
import { setTitle } from '../redux/actions/uiActions'
import { useDispatch, useSelector } from 'react-redux'

//import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom';

//import { fetchAssessments } from '../redux/actions/assessmentsActions'
import XTable from '../mui/XTable'
import Select from 'react-select'
import { init, setFilter, nextPage, prevPage, setPage, requestSortOrder } from '../redux/ekspert/viewAssessmentsPageActions';
import DelayedTextField from '../mui/DelayedTextField';
import Paging from '../mui/Paging';
import { statusEnum } from '../service/assessmentStatusEnum'
import { Checkbox, FormControlLabel } from '@material-ui/core';

const tableConfig = (sortColumn) => ({
    id: 'id',
    properties: [
        { header: 'Videnskabeligt navn', headerId: 'scientificName', property: 'speciesInformation.scientificName' },
        { header: 'Almindeligt navn',  headerId: 'vernacularName', property: 'speciesInformation.vernacularName' },
        { header: 'Population', property: 'speciesInformation.population' },
        { header: 'Familie', headerId: 'speciesFamily', property: 'speciesInformation.speciesFamily' },
        { header: 'Status', property: 'status', func: assessment => statusEnum.fromValue(assessment.status).label },
        { header: 'Kategori', property: 'category'}
    ],
    selectable: false,
    highlightHeader: sortColumn
})


const toOption = e => {
    if (!e) {
        return null
    }
    return { id: e.id, value: e.id, label: e.name }
}
const toObject = (id, list) => list.find(l => l.id === id)

const ViewAssessmentsPage = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { pages, count, assessments, filter, assessors, sortOrder } = useSelector(state => state.viewAssessmentsPage)
    const { user } = useSelector(state => state.app)
    const { loading } = useSelector(state => state.ui)

    const updateSelectedAssessor = (assessor) => {
        dispatch(setFilter({ ...filter, assessorId: assessor?.id }))
    }

    const updateFilter = (property, value) => {
        const _f = { ...filter }
        _f[property] = value
        dispatch(setFilter(_f))
    }

    useEffect(() => {
        dispatch(setTitle('Vurderinger'))
        dispatch(init(user))
    }, [dispatch, user])

    if (!assessors) {
        return (<div />)
    }

    //console.log(assessors, selectedAssessor)    
    return (
        <div>
            <Select
                isClearable={false}
                options={assessors.map(toOption)}
                onChange={value => { updateSelectedAssessor(value) }}
                value={toOption(toObject(filter.assessorId, assessors))}
            />
            <DelayedTextField value={filter.speciesName} label='Artsnavn' onChange={value => updateFilter('speciesName', value)} />
            <div style={{ display: 'inline-block', width: 32 }} />
            <DelayedTextField value={filter.speciesFamily} label='Familie' onChange={value => updateFilter('speciesFamily', value)} />
            <div style={{ height: 24 }} />
            <FormControlLabel control={<Checkbox checked={filter.listAll}
                onChange={(evt, value) => updateFilter('listAll', value)}
                name="listAll" />} label="Vis alle vurderinger"
            />
            <Paging
                count={count}
                pages={pages}
                page={filter.page}
                pageSize={filter.limit}
                onPrev={() => dispatch(prevPage())}
                onNext={() => dispatch(nextPage())}
                onGoto={page => dispatch(setPage(page))}
            />
            <XTable 
                loading={loading} 
                key={JSON.stringify(filter)} 
                size={'medium'} 
                onHeaderClicked={(header) => {if (header && header.headerId) dispatch(requestSortOrder(header.headerId));}}
                onRowClick={value => history.push(`/vurderinger/${value.id}`)} 
                data={assessments} tableConfig={tableConfig(sortOrder)} />
        </div>
    )
}

export default ViewAssessmentsPage