import { combineReducers } from 'redux'
import app, { AppStore } from './appReducer'
import editAssessmentMenu, { EditAssessmentMenuStore } from './editAssessmentMenuRed'
import editAssessment, { EditAssessmentStore } from './editAssessmentRed'
import geographyMap, { GeographyMapStore } from './geographyMapReducer'
import editLevested from './editLevestedRed'
import editSupplerendeOplysninger from './editSupplerendeOplysningerRed'
import ui, { UIStore } from './uiRed'

import adminEntities from '../admin/adminEntitiesReducer'
import manageAssessmentsPage from '../admin/manageAssessmentsPageReducer'
import createAssessmentsPage from '../admin/createAssessmentsPageReducer'
import manageUserPage from '../admin/manageUserPageReducer'
import manageAssessorsPage from '../admin/manageAssessorsPageReducer'
import manageSpeciesPage from '../admin/manageSpeciesPageReducer'
import managePubliceringerPage from '../admin/managePubliceringerPageReducer'

import ekspertEntities from '../ekspert/ekspertEntitiesReducer'
import viewAssessmentsPage from '../ekspert/viewAssessmentsPageReducer'
import viewQaAssessmentsPage from '../ekspert/viewQAAssessmentsPageReducer'
import viewFagligKommenteringPage from '../ekspert/viewFagligKommenteringPageReducer'
import assessmentQaPage, { AssessmentQAPageStore } from '../ekspert/assessmentQAPageReducer'
import userProfilePage from '../ekspert/userProfilePageReducer'
import { LOGOUT_OK } from '../actions/loginActions'

import crudPage from '../../genericCrud/sagas/crudPageReducer'

import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'

export interface RedlistStore {
  adminEntities: any
  manageAssessmentsPage: any
  createAssessmentsPage: any
  manageUserPage: any
  manageAssessorsPage: any
  manageSpeciesPage: any
  managePubliceringerPage: any
  ekspertEntities: any
  viewAssessmentsPage: any
  viewQaAssessmentsPage: any
  viewFagligKommenteringPage: any
  assessmentQaPage: AssessmentQAPageStore
  userProfilePage: any
  editAssessment: EditAssessmentStore
  geographyMap: GeographyMapStore
  editLevested: any
  editSupplerendeOplysninger: any
  editAssessmentMenu: EditAssessmentMenuStore
  ui: UIStore
  app: AppStore
  crudPage: any
}

const appReducer = combineReducers<RedlistStore>({
  adminEntities,
  manageAssessmentsPage,
  createAssessmentsPage,
  manageUserPage,
  manageAssessorsPage,
  manageSpeciesPage,
  managePubliceringerPage,
  ekspertEntities,
  viewAssessmentsPage,
  viewQaAssessmentsPage,
  viewFagligKommenteringPage,
  assessmentQaPage,
  userProfilePage,
  editAssessment,
  geographyMap,
  editLevested,
  editSupplerendeOplysninger,
  editAssessmentMenu,
  ui,
  app,
  crudPage,
})

const rootReducer = (state: FixMeLater, action: FixMeLater): RedlistStore => {
  if (action.type === LOGOUT_OK) {
    state = undefined
  }
  return appReducer(state, action)
}
export default rootReducer
