import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Grid,
    Typography,
    IconButton,
    Drawer,
    Button
} from '@material-ui/core'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import AsyncSelect from 'react-select/async'

import {
    manageSpeciesPageInit,
    createSpecies,
    updateSpecies,
    deleteSpecies,
    setFilter,
    showCreateDialog,
    hideCreateDialog,
    showEditDialog,
    hideEditDialog,
    nextPage,
    prevPage,
    setPage,
    setSpeciesGroupForSpeciesList
} from '../../redux/admin/manageSpeciesPageActions'
import XTable from '../../mui/XTable'

import CreateSpeciesDialog from './CreateSpeciesDialog'
import EditSpeciesDialog from './EditSpeciesDialog'
import DelayedTextField from '../../mui/DelayedTextField';
import Paging from '../../mui/Paging';
import SetSpeciesGroupDialog from './SetSpeciesGroupDialog';
import redlistApi from '../../service/redlistApi';

const tableConfig = {
    id: 'id',
    properties: [
        { header: 'Videnskabeligt navn', property: 'scientificName' },
        { header: 'Almindeligt navn', property: 'vernacularName' },
        { header: 'Familie', property: 'speciesFamilyName' },
        { header: 'Population', property: 'population' },
        { header: 'ArterDk', property: 'arterDkId' },
        { header: 'Gruppe', property: 'speciesGroupName' }        
    ],
    selectable: true
}
const loadFamilies = (inputValue, callback) => {
    redlistApi.loadSpeciesFamiliesByName(inputValue)
        .then(data => {
            console.log('fetched', data)
            if (inputValue && data.length > 0) {
                callback(data.map(sf => ({ value: sf.id, label: sf.name })))    
            } else {
                callback([{value: null, label: 'Vis alle familier'}].concat(data.map(sf => ({ value: sf.id, label: sf.name }))))
            }
        })
};

const ManageSpeciesPage = props => {
    const [ selectedIds, setSelectedIds ] = useState([])
    const [ speciesFamilyId, setSpeciesFamilyId ] = useState(null)
    const [ speciesFamilyName, setSpeciesFamilyName ] = useState('')
    const [ showSetSpeciesGroup, setShowSetSpeciesGroup] = useState(false)
    const dispatch = useDispatch()
    const { count, pages, filter, selectedSpecies, species, showEditSpecies, showCreateSpecies, queryId } = useSelector(state => state.manageSpeciesPage)

    useEffect(() => {
        dispatch(manageSpeciesPageInit())
    }, [dispatch])

    console.log(selectedIds)
    
    return (
        <Grid container spacing={2}>
            <Drawer open={showCreateSpecies} anchor='right' onClose={() => { dispatch(hideCreateDialog()) }} >
                <CreateSpeciesDialog
                    onClose={() => dispatch(hideCreateDialog())}
                    onSave={(value) => { dispatch(createSpecies(value)) }}
                />
            </Drawer>
            <Drawer open={Boolean(showEditSpecies && selectedSpecies)} anchor='right' onClose={() => { dispatch(hideEditDialog()) }} >
                <EditSpeciesDialog
                    species={selectedSpecies || {}}
                    onClose={() => dispatch(hideEditDialog())}
                    onSave={(value) => { dispatch(updateSpecies(selectedSpecies.id, value)) }}
                    onDelete={id => dispatch(deleteSpecies(id))}
                />
            </Drawer>
            <Drawer open={showSetSpeciesGroup} anchor='right' onClose={() => { dispatch(hideCreateDialog()) }} >
                <SetSpeciesGroupDialog
                    onClose={() => setShowSetSpeciesGroup(false)}
                    onSave={(value) => { dispatch(setSpeciesGroupForSpeciesList(selectedIds, value.speciesGroupId)) ; setShowSetSpeciesGroup(false); }}
                />
            </Drawer>
            <Grid item xs={12}>
                <Typography variant='h5'>Adminstrer Arter</Typography>
            </Grid>
            <Grid item xs={12}>
                <IconButton onClick={() => {
                    dispatch(showCreateDialog())
                }} size='medium' variant='contained' color='primary'><AddCircleRoundedIcon fontSize="large" /></IconButton>
            </Grid>
            <Grid item xs={12}>
                <DelayedTextField
                    label='Videnskabeligt navn'
                    value={filter.scientificName}
                    onChange={value => dispatch(setFilter({ ...filter, scientificName: value}))} /> 
                <span style={{ padding: 8 }} />
                <DelayedTextField
                    label='Almindeligt navn'
                    value={filter.vernacularName}
                    onChange={value => dispatch(setFilter({ ...filter, vernacularName: value}))} />    
                </Grid>
                <Grid item xs={12} md={6}>  
                    <AsyncSelect
                        
                        value={{ value: speciesFamilyId, label: speciesFamilyName }}
                        defaultOptions
                        loadOptions={loadFamilies}
                        onChange={e => { setSpeciesFamilyId(e.value); setSpeciesFamilyName(e.label); dispatch(setFilter({ ...filter, speciesFamilyId: e.value}))}}
                         />                         
            </Grid>
            <Grid xs={12} item>
                <Button variant='contained' color='secondary' onClick={() => setShowSetSpeciesGroup(true)} disabled={selectedIds.length === 0}>Sæt gruppe for valgte arter</Button>
            </Grid>
            <Grid xs={12} item container>
                <Paging
                    count={count}
                    pageSize={filter.limit}
                    pages={pages}
                    page={filter.page}
                    onNext={() => dispatch(nextPage())} onPrev={() => dispatch(prevPage())}
                    onGoto={(page) => dispatch(setPage(page))} />
            </Grid>
            <XTable
                key={queryId}
                data={species}
                tableConfig={tableConfig}
                onRowClick={value => dispatch(showEditDialog(value))}
                onSelectionChanged={ids => setSelectedIds(ids)}
            />
        </Grid>
    )
}

export default ManageSpeciesPage