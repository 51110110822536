import React, { ReactElement } from 'react'
import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'
import DelayedTextField from '../../mui/DelayedTextField'
import ClippedTextbox from '../../mui/ClippedTextbox'
import SectionTitle from '../../mui/SectionTitle'
import { FixMeLater } from './GlobalErrorMessage'
import { PropertyValidationResult } from '../sections/validators/sectionValidators'

export interface TextAreaReferenceProps {
    help? : () => ReactElement | null;
    disabled? : boolean;
    referenceValue? : string; 
    validation?: PropertyValidationResult;
    referenceTitle?: string;
    rowStyle? : FixMeLater;
    title : string
    onChange: (value : string) => void;
    value: string;
    error?: FixMeLater;
    helperText? : string;
}

const TextAreaReference = (props : TextAreaReferenceProps) => {
    const { help, disabled, referenceValue, validation, referenceTitle, rowStyle, title, onChange, value } = props
    let { error, helperText } = props
    if (validation) {
        error = !validation.ok
        helperText = validation.ok ? '' : validation.message
    }
    return (
        <Grid container item style={rowStyle} spacing={2}>
            <Grid item xs={12} md={8}>
                <SectionTitle disabled={disabled} label={title} help={help} />               
                <DelayedTextField
                    delay={1000}
                    disabled={disabled}
                    fullWidth
                    style={{ width: '100%', fontSize: '0.875rem', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
                    value={value}
                    onChange={(value : string) => onChange(value)}
                    error={error}
                    helperText={error && helperText}
                    rows={8}
                    variant="outlined"
                    multiline />
            </Grid>
            {referenceValue ?
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">{referenceTitle}</Typography>
                    <Button fullWidth style={{ float: 'left', backgroundColor: 'rgba(1,0,200,0.1)' }}
                        onClick={() => onChange(referenceValue)} variant="outlined">Anvend</Button>
                    <ClippedTextbox lines={7} text={referenceValue} />
                </Grid>
                :
                <Grid item xs={12} md={4} />}
        </Grid>
    )
}

export default TextAreaReference