import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Typography, Grid, Button
} from '@material-ui/core'

import Ecosystems from './Ecosystems'
import Hovedmiljoer from './Hovedmiljoer'
import Habitater from './Habitater'
import Levestedstyper from './Levestedstyper'
import OverordnedeSubstrater from './OverordnedeSubstrater'
import Substrater from './Substrater'
import SubstratFunktioner from './SubstratFunktioner'
import LevestedsHeader from './LevestedsHeader'

import { nextSubmenu } from '../../redux/editAssessment/editAssessmentMenuActions'
import {
    setHovedmiljo,
    setLevestedstype,
    setHabitat,
    setEcosystem,
    setOverordnetSubstrat,
    setSubstrat,
    anvendLevestedReference
} from '../../redux/actions/editLevestedActions'
import DietSpecies from './DietSpecies'

const mapSubstratFunktion = funktion => {
    switch (funktion) {
        case 'lever_af':
            return "Lever af"
        case 'lever_paa':
            return "Lever på"
        default:
            return funktion
    }
}

const LevestedsReference = props => {
    const dispatch = useDispatch()
    const { assessmentId, levested } = props
    if (!levested) {
        return null
    }
    const {
        hovedmiljoer,
        levestedstyper,
        ecosystems,
        habitater,
        overordnedeSubstrater,
        substrater,
        dietSpecies,
        dietGenus
    } = levested
    console.log('rr', levested)
    return (
        <div style={{ marginTop: 24, backgroundColor: 'rgba(1,0,200,0.1)', width: '100%', margin: 'auto' }}>            
            <Typography style={{ padding: 8 }} variant='h6'>Reference Art</Typography>
            <div style={{ padding: 8 }}>
                {hovedmiljoer ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Hovedmiljøer</Typography>
                        <Typography variant='body2'>
                            {hovedmiljoer.map(h => h.name).join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                {levestedstyper ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Levestedstyper</Typography>
                        <Typography variant='body2'>
                            {levestedstyper.map(h => h.name).join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                {ecosystems ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Økosystemer</Typography>
                        <Typography variant='body2'>
                            {ecosystems.map(h => h.name).join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                {habitater ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Habitater</Typography>
                        <Typography variant='body2'>
                            {habitater.map(h => h.name).join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                {overordnedeSubstrater ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Overordnede substrater</Typography>
                        <Typography variant='body2'>
                            {overordnedeSubstrater.map(h => h.name).join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                {substrater ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Substrater</Typography>
                        <Typography variant='body2'>
                            {substrater.map(s => s.name + ' (' + s.funktion.map(mapSubstratFunktion).join('/') + ')').join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                {dietGenus && dietGenus.length > 0 ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Lever af slægter</Typography>
                        <Typography variant='body2'>
                            {dietGenus.map(s => s.name).join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                {dietSpecies && dietSpecies.length > 0 ?
                    <React.Fragment>
                        <Typography variant='subtitle2'>Lever af arter</Typography>
                        <Typography variant='body2'>
                            {dietSpecies.map(s => s.scientificName).join(', ')}
                        </Typography>
                    </React.Fragment>
                    : null
                }
                <Button fullWidth variant='contained' onClick={() => dispatch(anvendLevestedReference(assessmentId, levested))}>Anvend</Button>
            </div>
        </div>
    )
}


const Levestedsangivelse = (props) => {
    const { referenceArt } = props
    const dispatch = useDispatch()
    const validationResult = useSelector(state => state.editAssessment.criteriaValidationResult?.levesteder)

    const assessmentId = useSelector(state => state.editAssessment.assessmentId)
    const levestedstyper = useSelector(state => state.ekspertEntities.levestedsDefinition.levestedstyper)
    const hovedmiljoer = useSelector(state => state.ekspertEntities.levestedsDefinition.hovedmiljoer)
    const ecosystems = useSelector(state => state.ekspertEntities.levestedsDefinition.ecosystems)
    const habitater = useSelector(state => state.ekspertEntities.levestedsDefinition.habitater)
    const overordnedeSubstrater = useSelector(state => state.ekspertEntities.levestedsDefinition.overordnedeSubstrater)
    const substrater = useSelector(state => state.ekspertEntities.levestedsDefinition.substrater)

    const selectedHovedmiljo = useSelector(state => state.editLevested.hovedmiljoer)
    const selectedLevestedstyper = useSelector(state => state.editLevested.levestedstyper)
    const selectedEcosystems = useSelector(state => state.editLevested.ecosystems)
    const selectedHabitats = useSelector(state => state.editLevested.habitater)
    const selectedOverordnedeSubstrater = useSelector(state => state.editLevested.overordnedeSubstrater)
    const selectedSubstrater = useSelector(state => state.editLevested.substrater)
    const currentTab = useSelector(state => state.editAssessmentMenu.currentSubmenu)

    const referenceLevested = referenceArt?.levested

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Typography variant='h5'>Levestedsangivelse</Typography>
                <div>
                     {validationResult.warning && <Typography color='secondary'>{validationResult.message}</Typography>}
                     {validationResult.error && <Typography color='secondary'>{validationResult.message}</Typography>}
                </div>
                <LevestedsHeader
                    hovedmiljoer={selectedHovedmiljo}
                    levestedstyper={selectedLevestedstyper}
                    ecosystems={selectedEcosystems}
                    habitats={selectedHabitats}
                    overSubstrater={selectedOverordnedeSubstrater}
                    substrater={selectedSubstrater}
                    currentTab={currentTab} />
            </Grid>
            <Grid item xs={4}>
                <LevestedsReference assessmentId={assessmentId} levested={referenceLevested} />
            </Grid>
            <Grid item xs={12}>
                {currentTab === 0 ?
                    <Hovedmiljoer
                        value={selectedHovedmiljo}
                        hovedmiljoer={hovedmiljoer}
                        onSelectedChanged={(value) => dispatch(setHovedmiljo(value))}
                        onProceed={() => dispatch(nextSubmenu())} /> : null}
                {currentTab === 1 ?
                    <Levestedstyper
                        value={selectedLevestedstyper}
                        levestedstyper={levestedstyper}
                        selectedHovedmiljoer={selectedHovedmiljo}
                        onSelectedChanged={(value) => dispatch(setLevestedstype(value))}
                        onProceed={() => dispatch(nextSubmenu())}
                    /> : null}
                {currentTab === 2 ?
                    <Ecosystems
                        value={selectedEcosystems}
                        hovedmiljoer={selectedHovedmiljo}
                        ecosystems={ecosystems}
                        selectedHabitats={selectedHabitats}
                        onSelectedChanged={(value) => dispatch(setEcosystem(value))}
                        onProceed={() => dispatch(nextSubmenu())}
                    /> :
                    null
                }
                {currentTab === 3 ?
                    <Habitater
                        selectedHabitats={selectedHabitats}
                        selectedEcosystems={selectedEcosystems}
                        habitater={habitater}
                        onSelectedChanged={(value) => {
                            dispatch(setHabitat(value))
                        }}
                        onProceed={() => dispatch(nextSubmenu())}
                    />
                    : null}
                {currentTab === 4 ?
                    <OverordnedeSubstrater
                        selectedOverordnedeSubstrater={selectedOverordnedeSubstrater}
                        hovedmiljoer={selectedHovedmiljo}
                        overordnedeSubstrater={overordnedeSubstrater}
                        onSelectedChanged={(value) => dispatch(setOverordnetSubstrat(value))}
                        onProceed={() => { dispatch(nextSubmenu()) }} />
                    : null
                }
                {currentTab === 5 ?
                    <Substrater
                        hovedmiljoer={selectedHovedmiljo}
                        selectedSubstrater={selectedSubstrater}
                        selectedOverordnedeSubstrater={selectedOverordnedeSubstrater}
                        substrater={substrater}
                        onSelectedChanged={(value) => dispatch(setSubstrat(value))}
                        onProceed={() =>  dispatch(nextSubmenu())} />
                    : null
                }
                {currentTab === 6 ?
                    <SubstratFunktioner
                        substrater={selectedSubstrater}
                        onProceed={() => dispatch(nextSubmenu())}
                    />
                    : null}
                {currentTab === 7 ?
                    <DietSpecies
                        substrater={selectedSubstrater}
                        onProceed={() => dispatch(nextSubmenu())}
                    />
                    : null}
            </Grid>
        </Grid>
    )
}

export default Levestedsangivelse
