import RedlistApi from '../../service/redlistApi'

import {
    put,
    debounce,
    takeEvery,
    select,
    all
} from 'redux-saga/effects'

import {
    REQUEST_SET_GRADIENT,
    SET_GRADIENT,
    SAVE_GRADIENT,
    REQUEST_SET_HABITAT,
    REQUEST_SET_OVERORDNET_SUBSTRAT
} from '../actions/editLevestedActions'

import {
    confirmAction,
    showSaving,
    hideSaving,
    showMessage,
    showError
} from '../actions/uiActions'

import {
    apiRequest
} from '../api/apiRequest'

import {
    REQUEST_SET_SUBSTRAT,
    REQUEST_SET_HOVEDMILJO,
    REQUEST_SET_LEVESTEDSTYPE,
    REQUEST_SET_ECOSYSTEM,
    SET_HOVEDMILJO,
    SET_SUBSTRAT,
    SET_LEVESTED,
    SAVE_LEVESTED,
    saveLevested,
    SET_ECOSYSTEM,
    SET_LEVESTEDSTYPE,
    SET_HABITAT,
    SET_OVERORDNET_SUBSTRAT,
    SET_SUBSTRAT_FUNKTION,
    SAVE_DIET_SPECIES,
    SAVE_DIET_GENUS,
    setEcosystem,
    setHabitat,
    setLevestedstype,
    setOverordnetSubstrat,
    setSubstrat,
    setHovedmiljo,
    LEVESTED_USE_REFERENCE,
    setSubstratFunktion,
    saveDietGenus,
    saveDietSpecies
} from '../actions/editLevestedActions';
import {
    ASSESSMENT_FETCHED, validateAssessment
} from '../editAssessment/editAssessmentActions';

const handleHovedmiljoer = (values, selectedLevesteder) => {
    const remainingEcosystems = selectedLevesteder.ecosystems.filter(e => Boolean(values.find(h => h.slug === e.hovedmiljoSlug)))
    const remainingOverordnedeSubstrater = selectedLevesteder.overordnedeSubstrater.filter(
        s => Boolean(
            values.find(h => s.hovedmiljoer.find(sh => {
                return sh === h.slug
            })))
    )
    let remainingHabitats = selectedLevesteder.habitater
    let remainingSubstrater = selectedLevesteder.substrater
    let lostValues = []
    if (remainingEcosystems.length < selectedLevesteder.ecosystems.length) {
        console.log('loosing ecosystems')
        remainingHabitats = selectedLevesteder.habitater.filter(h => Boolean(remainingEcosystems.find(e => e.id === h.ecosystemId)))
        lostValues = lostValues.concat(selectedLevesteder.ecosystems.filter(e => !Boolean(values.find(h => h.slug === e.hovedmiljoSlug))).map(e => e.name))
        lostValues = lostValues.concat(selectedLevesteder.habitater.filter(h => !Boolean(remainingEcosystems.find(e => e.id === h.ecosystemId))).map(h => h.name))
    }
    if (remainingOverordnedeSubstrater.length < selectedLevesteder.overordnedeSubstrater.length) {
        remainingSubstrater = selectedLevesteder.substrater.filter(s => Boolean(remainingOverordnedeSubstrater.find(o => s.overordnetSubstratId === o.id)))
        lostValues = lostValues.concat(
            selectedLevesteder.overordnedeSubstrater.filter(s => !Boolean(
                values.find(h => s.hovedmiljoer.find(sh => {
                    return sh === h.slug
                })))).map(s => s.name))
        lostValues = lostValues.concat(selectedLevesteder.substrater.filter(s => !Boolean(remainingOverordnedeSubstrater.find(o => s.overordnetSubstratId === o.id))).map(s => s.name))
    }

    if (lostValues.length > 0) {
        return confirmAction('Dette vil fjerne indtastede værdier', lostValues, [
            setHovedmiljo(values, true),
            setEcosystem(remainingEcosystems, true),
            setHabitat(remainingHabitats, true),
            setOverordnetSubstrat(remainingOverordnedeSubstrater, true),
            setSubstrat(remainingSubstrater, true)
        ])
    }

    return null
}

function* processRequestSetHovedmiljo(action) {
    try {
        const values = action.payload.value
        const {
            confirmed
        } = action.payload
        values.sort((a, b) => a.slug.localeCompare(b.slug))
        const {
            assessmentId, assessmentCriteria, threat
        } = yield select(state => state.editAssessment)
        const supplerendeOplysninger = yield  select(state => state.editSupplerendeOplysninger)
        const selectedLevesteder = yield select(state => state.editLevested)
        if (!confirmed) {
            const requestConfirmationAction = handleHovedmiljoer(values, selectedLevesteder)
            if (requestConfirmationAction) {
                yield put(requestConfirmationAction)
                return
            }
        }
        values.sort((a, b) => a.slug.localeCompare(b.slug))
        const body = values.map(v => ({
            hovedmiljoSlug: v.slug
        }))
        yield put({
            type: SET_HOVEDMILJO,
            payload: {
                value: values
            }
        })
        yield put(validateAssessment(assessmentCriteria, supplerendeOplysninger, { ...selectedLevesteder, hovedmiljoer: values}, threat))
        yield put(saveLevested(assessmentId, 'hovedmiljo', body))
    } catch (error) {
        yield put(showError(error))
    }
}


const handleLevestedstype = (values, selectedLevesteder) => {
    const hasHabitats = values.filter(v => v.hasHabitats).length > 0
    const hasSubstrates = values.filter(v => v.hasSubstrates).length > 0
    const actionsToConfirm = []
    let list = []
    if (!hasHabitats) {
        if (selectedLevesteder.habitater.length > 0) {
            actionsToConfirm.push(setHabitat([], true))
            list = list.concat(selectedLevesteder.habitater.map(h => h.name))
        }
        if (selectedLevesteder.ecosystems.length > 0) {
            actionsToConfirm.push(setEcosystem([], true))
            list = list.concat(selectedLevesteder.ecosystems.map(e => e.name))
        }
    }
    if (!hasSubstrates) {
        if (selectedLevesteder.overordnedeSubstrater.length > 0) {
            actionsToConfirm.push(setOverordnetSubstrat([], true))
            list = list.concat(selectedLevesteder.overordnedeSubstrater.map(o => o.name))
        }
        if (selectedLevesteder.substrater.length > 0) {
            actionsToConfirm.push(setSubstrat([], true))
            list = list.concat(selectedLevesteder.substrater.map(s => s.name))
        }
    }
    if (actionsToConfirm.length > 0) {
        actionsToConfirm.push(setLevestedstype(values, true))
        return confirmAction('Dette vil fjerne andre indtastede værdier', list, actionsToConfirm)
    }
    return false
}


function* processRequestSetLevestedstype(action) {
    try {
        const values = action.payload.value
        const {
            confirmed
        } = action.payload
        values.sort((a, b) => a.slug.localeCompare(b.slug))
        const {
            assessmentId
        } = yield select(state => state.editAssessment)
        const selectedLevesteder = yield select(state => state.editLevested)
        if (!confirmed) {
            const requestConfirmationAction = handleLevestedstype(values, selectedLevesteder)
            if (requestConfirmationAction) {
                yield put(requestConfirmationAction)
                return
            }
        }
        const body = values.map(v => ({
            levestedstypeSlug: v.slug
        }))
        yield put({
            type: SET_LEVESTEDSTYPE,
            payload: {
                value: values
            }
        })
        yield put(saveLevested(assessmentId, 'levestedstype', body))
    } catch (error) {
        yield put(showError(error))
    }
}

const handleEcosystems = (values, selectedLevesteder) => {
    const remainingHabitats = selectedLevesteder.habitater.filter(h => Boolean(values.find(e => e.id === h.ecosystemId)))

    if (remainingHabitats.length < selectedLevesteder.habitater.length) {
        const lostHabitats = selectedLevesteder.habitater.filter(h => !Boolean(values.find(e => e.id === h.ecosystemId)))
        //console.log('Dispatch confirmAction request')
        return confirmAction('Dette vil fjerne habitater', lostHabitats.map(h => h.name), [
            setEcosystem(values, true),
            setHabitat(remainingHabitats, true)
        ])
    }
    return false
}

function* processRequestSetEcosystem(action) {
    try {
        const values = action.payload.value
        const {
            confirmed
        } = action.payload
        values.sort((a, b) => a.id - b.id)
        const {
            assessmentId
        } = yield select(state => state.editAssessment)
        const selectedLevesteder = yield select(state => state.editLevested)
        if (!confirmed) {
            const requestConfirmationAction = handleEcosystems(values, selectedLevesteder)
            if (requestConfirmationAction) {
                yield put(requestConfirmationAction)
                return
            }
        }
        const body = values.map(v => ({
            ecosystemId: v.id
        }))
        yield put({
            type: SET_ECOSYSTEM,
            payload: {
                value: values
            }
        })
        yield put(saveLevested(assessmentId, 'ecosystem', body))
    } catch (error) {
        yield put(showError(error))
    }
}

const handleHabitater = (values, selectedLevesteder, hovedmiljoerDefinition, ecosystemsDefinition) => {
    const currentEcosystems = selectedLevesteder.ecosystems
    const requiredEcosystems = [...new Set(values.map(habitat => ({
        id: habitat.ecosystemId
    })))]
    const missingEcosystems = requiredEcosystems.filter(e => !Boolean(currentEcosystems.find(ce => ce.id === e.id)))
    console.log(missingEcosystems)
    const extraActions = []
    if (missingEcosystems.length > 0) {
        const newEcosystemIds = [...new Set(missingEcosystems.concat(currentEcosystems).map(e => e.id))]
        const newEcosystems = newEcosystemIds.map(id => ecosystemsDefinition.find(e => e.id === id))
        const currentHovedmiljoer = selectedLevesteder.hovedmiljoer
        const requiredHovedmiljoSlugs = newEcosystems.map(e => e.hovedmiljoSlug)
        const missingHovedmiljoSlugs = requiredHovedmiljoSlugs.filter(slug => !Boolean(currentHovedmiljoer.find(h => h.slug === slug)))
        if (missingHovedmiljoSlugs.length > 0) {
            const newHovedmiljoSlugs = [...new Set(missingHovedmiljoSlugs.concat(currentHovedmiljoer.map(h => h.slug)))]
            const newHovedmiljoer = newHovedmiljoSlugs.map(slug => hovedmiljoerDefinition.find(h => h.slug === slug))
            extraActions.push(setHovedmiljo(newHovedmiljoer, true))
        }
        extraActions.push(setEcosystem(newEcosystems, true))
    }
    return extraActions
}


function* processRequestSetHabitater(action) {
    try {
        const values = action.payload.value
        const {
            confirmed
        } = action.payload
        values.sort((a, b) => a.id - b.id)
        const {
            assessmentId
        } = yield select(state => state.editAssessment)
        const selectedLevesteder = yield select(state => state.editLevested)
        const ecosystemsDefinition = yield select(state => state.ekspertEntities.levestedsDefinition.ecosystems)
        const hovedmiljoerDefinition = yield select(state => state.ekspertEntities.levestedsDefinition.hovedmiljoer)
        if (!confirmed) {
            const extraActions = handleHabitater(values, selectedLevesteder, hovedmiljoerDefinition, ecosystemsDefinition)
            if (extraActions.length > 0) {
                yield all(extraActions.map(a => put(a)))
            }
        }
        yield put({
            type: SET_HABITAT,
            payload: {
                value: values
            }
        })
        const body = values.map(v => ({
            habitatId: v.id
        }))
        yield put(saveLevested(assessmentId, 'habitat', body))
    } catch (error) {
        console.log('error', error)
        yield put(showError(error))
    }
}



const handleOverordnedeSubstrater = (values, selectedLevesteder) => {
    const remainingSubstrater = selectedLevesteder.substrater.filter(s => Boolean(values.find(o => o.id === s.overordnetSubstratId)))

    if (remainingSubstrater.length < selectedLevesteder.substrater.length) {
        const lostSubstrater = selectedLevesteder.substrater.filter(s => !Boolean(values.find(o => o.id === s.overordnetSubstratId)))
        //console.log('Dispatch confirmAction request')
        return confirmAction('Dette vil fjerne substrater', lostSubstrater.map(s => s.name), [
            setOverordnetSubstrat(values, true),
            setSubstrat(remainingSubstrater, true)
        ])
    }
    return null
}

function* processRequestSetOverordnetSubstrat(action) {
    try {
        const values = action.payload.value
        const {
            confirmed
        } = action.payload
        values.sort((a, b) => a.id - b.id)
        const {
            assessmentId
        } = yield select(state => state.editAssessment)
        const selectedLevesteder = yield select(state => state.editLevested)
        if (!confirmed) {
            const requestConfirmationAction = handleOverordnedeSubstrater(values, selectedLevesteder)
            if (requestConfirmationAction) {
                yield put(requestConfirmationAction)
                return
            }
        }
        yield put({
            type: SET_OVERORDNET_SUBSTRAT,
            payload: {
                value: values
            }
        })
        const body = values.map(v => ({
            overordnetSubstratId: v.id
        }))
        yield put(saveLevested(assessmentId, 'overordnetSubstrat', body))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSaveDietGenus(action) {
    try {
        const {
            assessmentId,
            dietGenus
        } = action.payload
        const dietGenusIds = dietGenus.map(s => s.id)
        yield put(showSaving())
        yield apiRequest(RedlistApi, RedlistApi.updateDietGenus, [assessmentId, dietGenusIds])
        yield put(hideSaving())
    } catch (error) {
        yield put(showError(error))
    }
}


function* processSaveDietSpecies(action) {
    try {
        const {
            assessmentId,
            dietSpecies
        } = action.payload
        if (!dietSpecies) {
            yield put(showMessage('Fejl', 'Cannot save diet species "undefined" ' + JSON.stringify(action.payload)))
            return
        }
        const dietSpeciesIds = dietSpecies.map(s => s.id)
        yield put(showSaving())
        yield apiRequest(RedlistApi, RedlistApi.updateDietSpecies, [assessmentId, dietSpeciesIds])
        yield put(hideSaving())
    } catch (error) {
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processLevestedUseReference(action) {
    try {
        const assessmentId = action.payload.assessmentId
        const levested = action.payload.levestedReference
        yield put(setHovedmiljo(levested.hovedmiljoer, true))
        yield put(setLevestedstype(levested.levestedstyper, true))
        yield put(setEcosystem(levested.ecosystems, true))
        yield put(setHabitat(levested.habitater, true))
        yield put(setOverordnetSubstrat(levested.overordnedeSubstrater, true))
        yield put(setSubstrat(levested.substrater, true))
        yield put(saveDietGenus(assessmentId, levested.dietGenus))
        yield put(saveDietSpecies(assessmentId, levested.dietSpecies))
        yield all(levested.substrater.map(s => put(setSubstratFunktion(s, s.funktion, true))))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processFetchAssesmentOk(action) {
    try {
        const {
            item
        } = action.payload
        const levested = item.levested
        yield put({
            type: SET_LEVESTED,
            payload: {
                levested
            }
        })
    } catch (error) {
        yield put(showError(error))
    }
}


function* processSaveGradient(action) {
    const {
        assessmentId,
        gradientName,
        value
    } = action.payload
    const [ minValue, maxValue ] = value
    const {
        gradienter
    } = yield select(state => state.editLevested)
    try {
        yield put(showSaving())
        yield apiRequest(RedlistApi, RedlistApi.updateGradient, [assessmentId, gradientName, minValue, maxValue])
        yield put(hideSaving())
    } catch (error) {
        console.log(error)
        yield put({
            type: SET_GRADIENT,
            payload: {
                gradienter
            }
        })
        yield put(hideSaving())
        yield put(showError(error))
    }
}

function* processRequestSetGradient(action) {
    const {
        gradientName,
        value
    } = action.payload
    const {
        gradienter
    } = yield select(state => state.editLevested)
    try {
        console.log('gradienter', gradienter)
        const newGradienter = {
            ...gradienter
        }
        newGradienter[gradientName + 'Min'] = value[0]
        newGradienter[gradientName + 'Max'] = value[1]
        yield put({
            type: SET_GRADIENT,
            payload: {
                gradienter: newGradienter
            }
        })
        yield put({
            type: SAVE_GRADIENT,
            payload: action.payload
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: SET_GRADIENT,
            payload: {
                gradienter
            }
        })
        yield put(showError(error))
    }
}

function* processSaveLevested(action) {
    try {
        const {
            levested,
            assessmentId,
            values
        } = action.payload            
        yield put(showSaving())
        yield apiRequest(RedlistApi, RedlistApi.updateLevested, [assessmentId, levested, values])
        yield put(hideSaving())
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetSubstratFunktion(action) {
    try {
        const {
            assessmentId
        } = yield select(state => state.editAssessment)
        const {
            substrat,
            funktion
        } = action.payload
        yield put(saveLevested(assessmentId, 'substrat/' + encodeURI(substrat.id) + '/funktion', funktion))
    } catch (error) {
        yield put(showError(error))
    }
}

function* processSetSubstrat(action) {
    try {
        const {
            assessmentId
        } = yield select(state => state.editAssessment)
        const prevSubstrater = yield select(state => state.editLevested.substrater)
        const substrater = action.payload.value.map(s => {
            return {
                id: s.id,
                name: s.name,
                overordnetSubstratId: s.overordnetSubstratId,
                funktion: prevSubstrater.find(ps => s.id === ps.id)?.funktion || []
            }
        })
        substrater.sort((a, b) => a.id - b.id)
        yield put({
            type: SET_SUBSTRAT,
            payload: {
                value: substrater
            }
        })
        const values = substrater.map(s => ({
            substratId: s.id,
            funktioner: s.funktion
        }))
        yield put(saveLevested(assessmentId, 'substrat', values))
    } catch (error) {
        yield put(showError(error))
    }
}


export default function* watcher() {
    yield takeEvery(ASSESSMENT_FETCHED, processFetchAssesmentOk)
    yield takeEvery(LEVESTED_USE_REFERENCE, processLevestedUseReference)

    yield takeEvery(REQUEST_SET_HOVEDMILJO, processRequestSetHovedmiljo)
    yield takeEvery(REQUEST_SET_LEVESTEDSTYPE, processRequestSetLevestedstype)
    yield takeEvery(REQUEST_SET_ECOSYSTEM, processRequestSetEcosystem)
    yield takeEvery(REQUEST_SET_HABITAT, processRequestSetHabitater)    
    yield takeEvery(REQUEST_SET_OVERORDNET_SUBSTRAT, processRequestSetOverordnetSubstrat)
    yield takeEvery(REQUEST_SET_SUBSTRAT, processSetSubstrat)
    yield takeEvery(SET_SUBSTRAT_FUNKTION, processSetSubstratFunktion)
    yield takeEvery(SAVE_LEVESTED, processSaveLevested)

    yield takeEvery(SAVE_DIET_GENUS, processSaveDietGenus)
    yield takeEvery(SAVE_DIET_SPECIES, processSaveDietSpecies)

    yield takeEvery(REQUEST_SET_GRADIENT, processRequestSetGradient)
    yield debounce(500, SAVE_GRADIENT, processSaveGradient)
}