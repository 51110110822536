import { FixMeLater } from "../../assessments/components/GlobalErrorMessage";

export const SHOW_SPINNER      = '[ui] show spinner';
export const HIDE_SPINNER      = '[ui] hide spinner';
export const SHOW_SAVING      = '[ui] show saving';
export const HIDE_SAVING      = '[ui] hide saving';
export const SHOW_MENU         = '[ui] show menu';
export const HIDE_MENU         = '[ui] hide menu';
export const SET_TITLE         = '[ui] set title';
export const ENABLE_GOBACK = '[ui] go back on'
export const DISABLE_GOBACK ='[ui] go back off'
export const CONFIRM_ACTION = '[ui] confirm action on'
export const CONFIRM_ACTION_ACCEPTED = '[ui] confirm action accept'
export const CONFIRM_ACTION_REJECTED = '[ui] confirm action reject'
export const SHOW_MESSAGE = '[ui] show message';
export const HIDE_MESSAGE = '[ui] hide message';
export const SHOW_NOT_FOUND = '[ui] not found'
export const GOTO = '[ui] goto'

export const showSpinner = (id : FixMeLater) => ({
  type: SHOW_SPINNER,
  payload: { id }
});

export const hideSpinner = (id : FixMeLater) => ({
  type: HIDE_SPINNER,
  payload: { id }
});

export const showSaving = () => ({
  type: SHOW_SAVING
});

export const hideSaving = () => ({
  type: HIDE_SAVING
});

export const showMenu = () => ({
  type: SHOW_MENU
})

export const hideMenu = () => ({
  type: HIDE_MENU
})

export const setTitle = (title : string) => ({
  type: SET_TITLE,
  payload: { title }
})

export const showGoBack = () => ({
  type: ENABLE_GOBACK,
  payload: {}
})

export const hideGoBack = () => ({
  type: DISABLE_GOBACK,
  payload: {}
})

export const confirmAction = (header : string, contentList : FixMeLater, actions : FixMeLater[]) => ({
  type: CONFIRM_ACTION,
  payload: {
    header,
    contentList,
    actions
  }
})

export const confirmActionAccepted = (actions : FixMeLater[]) => ({
  type: CONFIRM_ACTION_ACCEPTED,
  payload: { actions }
})

export const confirmActionRejected = () => ({
  type: CONFIRM_ACTION_REJECTED
})

export const showNotFound = (title : string, message : string) => ({
  type: SHOW_NOT_FOUND,
  payload: { title, message }
})

export const showMessage = (title : string, text : string | null, action : FixMeLater) => ({
  type: SHOW_MESSAGE,
  payload: { title, text, action }
})

export const showError = (error : any, title? : string) => {
  const _title = title || 'Fejl'
  if (error && error.error) {    
    return showMessage(_title, JSON.stringify(error), null)
  }
  const errorStr = error && error.toString ? error.toString() : ''
  return showMessage(_title, errorStr, null)
}

export const hideMessage = (action? : FixMeLater) => ({
  type: HIDE_MESSAGE,
  payload: { action }
})

export const goto = (urlHash : string, enableGoback : boolean) => ({
  type: GOTO,
  payload: { urlHash, enableGoback }
})