import { FixMeLater } from '../../assessments/components/GlobalErrorMessage'
import { 
  SET_HOVEDMILJO, 
  SET_LEVESTEDSTYPE,
  SET_ECOSYSTEM,
  SET_HABITAT,
  SET_OVERORDNET_SUBSTRAT,
  SET_SUBSTRAT,
  SET_SUBSTRAT_FUNKTION,
  SET_LEVESTED,
  SAVE_DIET_SPECIES,
  SAVE_DIET_GENUS,
  SET_GRADIENT
} from '../actions/editLevestedActions'

const initialState = {
  dietSpecies: [],
  dietGenus: [],
  hovedmiljoer: [],
  levestedstyper: [],
  ecosystems: [],
  habitater: [],
  overordnedeSubstrater: [],
  substrater: []
}

const editLevested = (state = initialState, action : FixMeLater) => {
  //console.log('action', action);
    switch (action.type) { 
      case SET_LEVESTED:
        return { ...action.payload.levested } 
      case SET_HOVEDMILJO:
        return { ...state, hovedmiljoer: action.payload.value }
      case SET_LEVESTEDSTYPE: 
        return { ...state, levestedstyper: action.payload.value}
      case SET_ECOSYSTEM: 
        return { ...state, ecosystems: action.payload.value}
      case SET_HABITAT: 
        return { ...state, habitater: action.payload.value}    
      case SET_GRADIENT:
        return { ...state, gradienter: action.payload.gradienter }  
      case SET_OVERORDNET_SUBSTRAT: 
        return { ...state, overordnedeSubstrater: action.payload.value}      
      case SET_SUBSTRAT: 
        return { ...state, substrater: action.payload.value}      
      case SET_SUBSTRAT_FUNKTION:
        const substrat : FixMeLater = state.substrater.find((s : FixMeLater) => s.id === action.payload.substrat.id)
        const rem = state.substrater.filter((s : FixMeLater) => s.id !== action.payload.substrat.id)
        const result = rem.concat({ ...substrat, funktion: action.payload.funktion } )
        result.sort((a : FixMeLater, b : FixMeLater) => a.id - b.id)
        return { ...state, substrater: result }
        case SAVE_DIET_SPECIES:
          return { ...state, dietSpecies: action.payload.dietSpecies }
        case SAVE_DIET_GENUS:
          return { ...state, dietGenus: action.payload.dietGenus }
            default:
        return state
    }
  }
  
  export default editLevested