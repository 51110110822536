import { useEffect, useState } from "react";
import { Box, Typography, LinearProgress } from "@material-ui/core";

import OlLayers from "../../ol/OlLayers";
import { OlTileLayer } from "../../ol/OlLayers";
import OlView from "../../ol/OlView";
import OpenLayersMap from "../../ol/OpenLayersMap";
import OlWebGlPointsLayer from "../../ol/OlWebGlPointsLayer";
import OlStaticSource from "../../ol/OlSource";
import {
  cellStyle,
  createFeatureCollection,
  createFeatureCollectionFromOverviewCells,
  createFeatureCollectionFromPolygon,
  emptyFeatureCollection,
  polygonStyle,
} from "../../ol/olUtil";
import { useDispatch, useSelector } from "react-redux";
import { RedlistStore } from "../../redux/reducers";
import OlVectorLayer from "../../ol/OlVectorLayer";
import { BBox, Feature } from "../../ol/OlFeatureCollection";

import {
  gridCellToFeature,
  pointToAOOGrid,
  recordsToGridCells,
  simpleWgs84toEpsg25832,
} from "../../utils/gridHelper";
import {
  SnapshotMetadata,
  SnapshotRow,
  TaxonPositionInfo,
} from "../../service/arterDkModels";
import { Geometry } from "@turf/helpers";
//import TaxonRecordInfoDialog from "./TaxonRecordInfoDialog";

import arterDkApi from "../../service/arterDkApi";
import { ConvexHullArea, getConvexHull } from "../../utils/hullHelper";
import { showError } from "../../redux/actions/uiActions";

const mapView = { lat: 56, lon: 10.5, zoom: 7 };
const buffer = 0.015;
  
const cellPointStyle = {
  symbol: {
    symbolType: "circle",
    size: ["interpolate", ["linear"], ["zoom"], 5, 6, 10, 10, 17, 18],
    color: "rgb(196,64,255)",
    opacity: ["case", ["==", ["get", "deleted"], 1], 0.3, 0.9],
  },
};

const GeographyMapViewComponent = () => {
  const dispatch = useDispatch();
  const arterDkId = useSelector(
    (state: RedlistStore) =>
      state.assessmentQaPage?.assessment?.speciesInformation?.arterDkId
  );
  const snapshotId = useSelector(
    (state: RedlistStore) =>
      state.assessmentQaPage?.assessment?.assessmentCriteria?.geography
        .geographySnapshotId
  );

  const [snapshotMetadata, setSnapshotMetadata] =
    useState<SnapshotMetadata | null>(null);
  const [snapshotRows, setSnapshotRows] = useState<SnapshotRow[]>([]);
  const [points, setPoints] = useState<TaxonPositionInfo[]>([]);
  const [cells, setCells] = useState<TaxonPositionInfo[]>([]);
  const [convexHull, setConvexHull] = useState<ConvexHullArea | null>(null);

  const taxonId: string = arterDkId ? (arterDkId as string) : "";
  const [zoom, setZoom] = useState<number | undefined>();
  const [viewExtent, setViewExtent] = useState<BBox | null>(null);

  console.log({convexHull})

  useEffect(() => {
    console.log("mapview", { snapshotId });
    const init = async (snapshotId: string) => {
      try {
        const snapshotmetaResponse = await arterDkApi.getSnapshotMetadata(
          snapshotId
        );
        const snapshotmeta = snapshotmetaResponse.item;
        setSnapshotMetadata(snapshotmeta);
        const rows = await arterDkApi.getSnapshotRows(snapshotId);
        setSnapshotRows(rows.items);
        let p = rows.items.map((row: SnapshotRow): TaxonPositionInfo => {
          return {
            id: row.id,
            cellId: row.cellId,
            position: row.position,
            taxonId: snapshotmeta ? snapshotmeta.taxonId : "",
            deleted: false,
            positionHash: row.positionHash,
            year: row.year,
          };
        });
        setPoints(p);
        setConvexHull(getConvexHull(p));

        const c = recordsToGridCells(p);
        setCells(c);
      } catch (error) {
        dispatch(showError(error, "Kunne ikke hente data fra arter.dk"));
      }
    };
    if (snapshotId) {
      init(snapshotId);
    }
  }, [taxonId, dispatch, snapshotId]);

  if (snapshotId === null) {
    return null;
  }

  let _points = [];

  let precision = 5;
  if (zoom) {
    if (zoom > 10) {
      precision = 10;
    }
    if (zoom > 9.5) {
      precision = 8;
    } else if (zoom > 8.5) {
      precision = 7;
    } else if (zoom > 7.5) {
      precision = 6;
    } else if (zoom > 6.8) {
      precision = 5;
    }
  }

  let tmp: Record<string, TaxonPositionInfo> = {};
  points.forEach((p) => {
    const key = p.positionHash
      ? p.positionHash.substring(0, precision)
      : `${p.cellId}`;
    if (!tmp[key]) {
      tmp[key] = p;
    }
  });
  for (let key in tmp) {
    _points.push(tmp[key]);
  }
  _points = _points.filter((c: TaxonPositionInfo) => {
    if (!viewExtent) {
      return true;
    }
    if (c.position.coordinates[0] < viewExtent.bottomLeft.lon - buffer * 4) {
      return false;
    }
    if (c.position.coordinates[0] > viewExtent.topRight.lon + buffer * 4) {
      return false;
    }
    if (c.position.coordinates[1] < viewExtent.bottomLeft.lat - buffer * 4) {
      return false;
    }
    if (c.position.coordinates[1] > viewExtent.topRight.lat + buffer * 4) {
      return false;
    }
    return !c.deleted;
  });

  const hull = convexHull
    ? createFeatureCollectionFromPolygon(convexHull.hull)
    : emptyFeatureCollection();

  const loading = snapshotMetadata === null || snapshotRows.length === 0;

  const tmpPositions =
    viewExtent && zoom && zoom > 8
      ? cells.filter((c: TaxonPositionInfo) => {
          if (viewExtent) {
            if (
              c.position.coordinates[0] <
              viewExtent.bottomLeft.lon - buffer
            ) {
              return false;
            }
            if (c.position.coordinates[0] > viewExtent.topRight.lon + buffer) {
              return false;
            }
            if (
              c.position.coordinates[1] <
              viewExtent.bottomLeft.lat - buffer
            ) {
              return false;
            }
            if (c.position.coordinates[1] > viewExtent.topRight.lat + buffer) {
              return false;
            }
          }
          return !c.deleted;
        })
      : [];

  const gridcellFeatures: Feature[] = tmpPositions.map(
    (c: TaxonPositionInfo): Feature => {
      const utmCoords = simpleWgs84toEpsg25832(
        c.position.coordinates as number[]
      );
      const utmGeom: Geometry = {
        type: "Point",
        coordinates: utmCoords,
      };
      const gridCell = pointToAOOGrid(utmGeom);
      return gridCellToFeature(gridCell);
    }
  );

  const gcfc = createFeatureCollection(gridcellFeatures);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {loading ? <LinearProgress variant="indeterminate" /> : null}
      <div style={{ marginTop: 8, marginLeft: 32, marginRight: 32 }}></div>
      <OpenLayersMap
        id="species-occurrence-map"
        mouseWheelZoomSettings="ctrl"
        doubleClickZoom={false}
        left={0}
        right={0}
        top={0}
        height={600}
        onMoveEnd={(extent: BBox, zoom?: number) => {
          setViewExtent(extent);
          setZoom(zoom);
        }}
      >
        <OlView lat={mapView.lat} lon={mapView.lon} zoom={mapView.zoom} />
        <OlLayers>
          <OlTileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <OlVectorLayer visible={true} style={polygonStyle}>
            <OlStaticSource
              featureCollection={hull}
              sourceProjection="EPSG:4326"
              targetProjection="EPSG:3857"
            />
          </OlVectorLayer>
          <OlWebGlPointsLayer
            title="points"
            style={cellPointStyle}
            visible={true}
          >
            <OlStaticSource
              featureCollection={createFeatureCollectionFromOverviewCells(
                _points
              )}
              sourceProjection="EPSG:4326"
              targetProjection="EPSG:3857"
            />
          </OlWebGlPointsLayer>
          <OlVectorLayer visible={true} style={cellStyle}>
            <OlStaticSource
              featureCollection={gcfc}
              sourceProjection="EPSG:4326"
              targetProjection="EPSG:3857"
            />
          </OlVectorLayer>
        </OlLayers>
      </OpenLayersMap>
      <div style={{ marginLeft: 8, marginTop: 4 }}>
        <Typography variant="caption">Beregnet</Typography>
        <Typography variant="body1">
          Udbredelsesareal: {convexHull ? Math.round(convexHull.area) : "-"}
        </Typography>
        <Typography variant="body1">
          Forekomstsareal: {cells.length * 4}
        </Typography>
      </div>
    </Box>
  );
};

export default GeographyMapViewComponent;
