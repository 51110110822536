import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const LevestedstypeBox = styled.div`
    width: 100%;    
    min-width: 100%;
    display: 'inline-block';
    background-color: #4caf50;
    border-radius: 4px;
    color: black;
    padding: 8px;
`
const HovedmiljoBox = styled.div`
    width: 100%;
    min-width: 100%;
    display: 'inline-block';
    background-color: #3D94F6;
    border-radius: 4px;
    color: white;
    padding: 8px;
`

const EcoBox = styled.div`
    width: 100%;
    min-width: 100%;
    display: 'inline-block';
    background-color: #ffc107;
    border-radius: 4px;
    color: #000;
    padding: 8px;
`

/*
const LinkedEcoBox = styled.div`
    width: 100%;
    min-width: 100%;
    display: 'inline-block';
    background-color: #ffe082;
    border-radius: 4px;
    width: 100%;
    min-width: 100%;
    color: #000;
    padding: 8px;
`
*/

//ff9e80
const HabitatBox = styled.div`
    width: 100%;
    min-width: 100%;
    display: 'inline-block';
    background-color: #ff9e80;
    border-radius: 4px;
    width: 100%;
    min-width: 100%;
    color: #000;
    padding: 8px;
`

const OverSubBox = styled.div`
    width: 100%;
    min-width: 100%;
    display: 'inline-block';
    background-color: #673ab7;
    border-radius: 4px;
    color: white;
    padding: 8px;
`

const SubBox = styled.div`
    width: 100%;
    min-width: 100%;
    display: 'inline-block';
    background-color: #ba68c8;
    border-radius: 4px;
    color: white;
    padding: 8px;
`

const LevestedsHeader = (props) => {
    let { hovedmiljoer, levestedstyper, ecosystems, habitats, overSubstrater, substrater } = props
    // { currentTab !== 0 && hovedmiljoer && hovedmiljoer.length > 0 
    return (
        <div style={{ marginTop: 16, marginBottom: 8 }}>
            <Grid item container xs={12} lg={11} spacing={1}>
                {hovedmiljoer && hovedmiljoer.length > 0 ?
                    <Grid item container xs={12} spacing={2}>{
                        hovedmiljoer.map(h => {
                            return <Grid key={h.slug} item xs={12} sm={6} md={4}><HovedmiljoBox>{h.name}</HovedmiljoBox></Grid>
                        })}
                    </Grid>
                    : null
                }

                {levestedstyper && levestedstyper.length > 0 ?
                    <Grid item container xs={12} spacing={1}>
                        {levestedstyper.map(l => {
                            return <Grid key={l.name} item xs={12} sm={6} md={4}><LevestedstypeBox>{l.name}</LevestedstypeBox></Grid>
                        })}
                    </Grid> : null}
                {ecosystems && ecosystems.length > 0 ?
                    <Grid item container xs={12} spacing={1}>{
                        ecosystems.map(h => {
                            return <Grid key={h.id} item xs={12} sm={6} md={4}><EcoBox key={h.id}>{h.name}</EcoBox></Grid>
                        })}
                    </Grid> : null}
                {habitats && habitats.length > 0 ?
                    <Grid item container xs={12} spacing={1}>{
                        habitats.map(h => {
                            return <Grid key={h.id} item xs={12} sm={6} md={4}><HabitatBox key={h.id}>{h.name}</HabitatBox></Grid>
                        })}
                    </Grid> : null}
                {overSubstrater && overSubstrater.length > 0 ?
                    <Grid item container xs={12} spacing={1}>{
                        overSubstrater.map(h => {
                            return <Grid key={h.id} item xs={12} sm={6} md={4}><OverSubBox key={h.id}>{h.name}</OverSubBox></Grid>
                        })}
                    </Grid> : null}
                {substrater && substrater.length > 0 ?
                    <Grid item container xs={12} spacing={1}>{
                        substrater.map(h => {
                            return <Grid key={h.id} item xs={12} sm={6} md={4}><SubBox key={h.id}>{h.name}</SubBox></Grid>
                        })}
                    </Grid> : null}
            </Grid>
        </div>
    )
}

export default LevestedsHeader